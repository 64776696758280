import { FC, useMemo, useState } from "react";

import { Spinner, SectionHeading, Button, Column, Row, Text, SearchInput, Tooltip } from "@hightouchio/ui";
import { Route, Routes, useNavigate } from "react-router-dom";

import relationshipPlaceholder from "src/assets/placeholders/relationship.svg";
import searchPlaceholder from "src/assets/placeholders/search.svg";
import throughRelationshipPlaceholder from "src/assets/placeholders/through-relationship.svg";
import { useRelationshipModelsQuery, useRelationshipsQuery, useThroughRelationshipsQuery } from "src/graphql";
import { RelationshipForm } from "src/pages/schema/relationships/relationship";
import { ThroughRelationshipForm } from "src/pages/schema/relationships/through-relationship";
import { cardinalityOptions } from "src/pages/schema/relationships/utils";
import { SchemaModelType } from "src/types/schema";
import { MergeIcon } from "src/ui/icons/new-icons/merge";
import { Table, TableColumn } from "src/ui/table";
import { TextWithTooltip } from "src/ui/text";
import { getSearchRegExp } from "src/utils/string";

export const Relationships: FC<
  Readonly<{
    type: SchemaModelType;
    sourceId: string;
    modelId: string;
  }>
> = ({ type, modelId, sourceId }) => {
  const [search, setSearch] = useState("");
  const { data: relationships } = useRelationshipsQuery(
    { id: String(modelId) },
    { enabled: Boolean(modelId), select: (data) => data.listRelationships.relationships },
  );
  const { data: throughRelationships } = useThroughRelationshipsQuery(
    { modelId: String(modelId) },
    { enabled: Boolean(modelId), select: (data) => data.model_relationships },
  );
  const { data: models } = useRelationshipModelsQuery({ sourceId }, { select: (data) => data.segments });

  const navigate = useNavigate();

  const relationshipColumns: TableColumn[] = [
    {
      name: "Name",
      cell: (row) => {
        const toModel = models?.find((m) => String(m.id) === String(row.to));
        return (
          <Row gap={2} align="center">
            <Text isTruncated fontWeight="medium">
              {row.toName || toModel?.name}
            </Text>
            {row.isMergingIntoTo && (
              <Tooltip message={`Merging columns into ${toModel?.name}`}>
                <MergeIcon />
              </Tooltip>
            )}
          </Row>
        );
      },
    },
    {
      name: "Model",
      cell: (row) => {
        const toModel = models?.find((m) => String(m.id) === String(row.to));
        return <Text isTruncated>{toModel?.name}</Text>;
      },
    },
    {
      name: "Type",
      cell: (row) => <Text>{cardinalityOptions.find((opt) => opt.value === row.cardinality)?.label}</Text>,
    },
  ];

  const throughRelationshipColumns: TableColumn[] = [
    {
      name: "Name",
      cell: (row) => <TextWithTooltip fontWeight="medium">{row.name ?? row.to_model.name}</TextWithTooltip>,
    },
    {
      name: "Access",
      cell: (row) => <TextWithTooltip>{row.to_model.name}</TextWithTooltip>,
    },
    {
      name: "Through",
      cell: (row) => (
        <TextWithTooltip>
          {row.through_relationships?.map(({ relationship: { name, to_model } }) => `${name ?? to_model.name}`).join(" -> ")}
        </TextWithTooltip>
      ),
    },
  ];

  const filteredRelationships = useMemo(() => {
    const regex = getSearchRegExp(search, "i");
    return (relationships ?? []).filter(({ toName }) => regex.test(String(toName)));
  }, [search, relationships]);

  if (!models || !relationships) {
    return <Spinner size="lg" m="auto" />;
  }

  return (
    <Routes>
      <Route
        index
        element={
          <Column gap={10} flex={1} overflow="auto" p={6}>
            <Column gap={4}>
              <Row align="center" justify="space-between" gap={4}>
                <SectionHeading>Relationships</SectionHeading>
                <Row align="center" gap={4}>
                  <SearchInput
                    width="xs"
                    placeholder="Search relationships..."
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate({ pathname: "new", search: window.location.search });
                    }}
                  >
                    Add relationship
                  </Button>
                </Row>
              </Row>
              <Table
                primaryKey="edgeId"
                columns={relationshipColumns}
                data={filteredRelationships}
                onRowClick={(row) => {
                  navigate({ pathname: String(row.edgeId), search: window.location.search });
                }}
                placeholder={{
                  image: search ? searchPlaceholder : relationshipPlaceholder,
                  title: search ? "No relationships match your search" : "This model contains no relationships",
                  body: search ? null : "Add relationship to increase the query capabilities of your audiences.",
                }}
              />
            </Column>
            {Boolean(type === SchemaModelType.Parent || throughRelationships?.length) && (
              <Column gap={4}>
                <Row align="center" justify="space-between" gap={4}>
                  <SectionHeading>Through relationships</SectionHeading>
                  <Row align="center" gap={4}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate({ pathname: "through/new", search: window.location.search });
                      }}
                    >
                      Add through relationship
                    </Button>
                  </Row>
                </Row>
                <Table
                  columns={throughRelationshipColumns}
                  data={throughRelationships}
                  onRowClick={(row) => navigate({ pathname: `through/${row.id}`, search: window.location.search })}
                  placeholder={{
                    image: throughRelationshipPlaceholder,
                    title: "This model contains no through relationships",
                    body: "Add a relationship to increase the query capabilities of your audiences.",
                  }}
                />
              </Column>
            )}
          </Column>
        }
      />
      <Route path=":id" element={<RelationshipForm models={models} modelId={modelId} sourceId={sourceId} />} />
      <Route path="new" element={<RelationshipForm models={models} modelId={modelId} sourceId={sourceId} />} />
      <Route
        path="through/:id"
        element={
          <ThroughRelationshipForm throughRelationshipModels={throughRelationships} modelId={modelId} sourceId={sourceId} />
        }
      />
      <Route
        path="through/new"
        element={
          <ThroughRelationshipForm throughRelationshipModels={throughRelationships} modelId={modelId} sourceId={sourceId} />
        }
      />
    </Routes>
  );
};
